var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-sheet theme--light br-0",attrs:{"elevation":1}},[_c('v-card-title',[_c('v-row',{staticClass:"col-md-12"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_vm._v(" Ejes K'atun | Administración ")]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{staticClass:"mb-2 float-right light-blue-502",attrs:{"color":"","dark":""},on:{"click":_vm.modalNuevo}},[_vm._v(" Registrar Eje K'atun ")])],1)],1),_c('v-row',{staticClass:"col-md-12 m-0 p-0"},[_c('v-divider')],1)],1),_c('v-card-text',{staticClass:"pl-0 pr-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filtro,"loading":_vm.tableLoading,"header-props":{
          sortByText: 'Ordenar por'
        },"no-data-text":"No se encontraron registros","loading-text":"Cargando...","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          'items-per-page-text': 'Registros por página',
          pageText: '{0}-{1} de {2}'
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","xs":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"off","dense":"","filled":"","color":"blue-grey lighten-2","label":"Nombre del Eje K'atun","maxlength":"200"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","xs":"6"}},[_c('v-select',{attrs:{"items":_vm.estados,"loading":false,"dense":"","filled":"","label":"Estado","no-data-text":'No existen estados registrados',"menu-props":"auto","return-object":""},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"cols":"2","md":"2","sm":"12","xs":"6"}},[_c('v-btn',{staticClass:"ma-0 white--text d-none",attrs:{"medium":"","color":"light-blue darken-2"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.nombreEje))]),_c('td',[_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"label":"","color":item.estadosId === 1
                    ? 'blue-grey lighten-4'
                    : item.estadosId === 2
                    ? 'deep-orange lighten-4'
                    : item.estadosId === 6
                    ? 'cyan lighten-4'
                    : item.estadosId === 7
                    ? 'deep-orange lighten-4'
                    : 'pink lighten-4',"text-color":item.estadosId === 1
                    ? 'blue-grey lighten-1'
                    : item.estadosId === 2
                    ? 'deep-orange lighten-1'
                    : item.estadosId === 6
                    ? 'cyan darken-1'
                    : item.estadosId === 7
                    ? 'deep-orange lighten-1'
                    : 'pink darken-1',"small":""}},[_vm._v(" "+_vm._s(item.estado)+" ")])],1),_c('td',[_c('v-btn',{staticClass:"\n                        ma-2\n                        btn-bg-light\n                        blue-grey--text\n                        lighten-2--text\n                        font-weight-medium\n                        text-capitalize\n                      ",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.obtenerDatosItem(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Actualizar ")],1),_c('v-btn',{staticClass:"\n                        ma-2\n                        btn-bg-light\n                        blue-grey--text\n                        lighten-2--text\n                        font-weight-medium\n                        text-capitalize\n                      ",attrs:{"small":"","depressed":"","color":"blue-grey lighten-5"},on:{"click":function($event){return _vm.mostrarDialogEstadoItem(
                    item.id,
                    item.estadosId,
                    item.estado,
                    item.nombreEje
                  )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.estadosId === 1 ? "mdi-delete" : "mdi-file-check-outline"))]),_vm._v(" "+_vm._s(item.estadosId === 1 ? "Inhabilitar" : "Habilitar")+" ")],1)],1)])]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"500","transition":"scroll-y-transition"},model:{value:(_vm.dialogSuspension),callback:function ($$v) {_vm.dialogSuspension=$$v},expression:"dialogSuspension"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Suspensión del proyecto ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_vm._v(" Está seguro de suspender el proyecto "+_vm._s(_vm.proyectoSeleccionado)+" ")])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Continuar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"900","transition":"scroll-y-transition","persistent":"","scrollable":""},model:{value:(_vm.dialogRegistro),callback:function ($$v) {_vm.dialogRegistro=$$v},expression:"dialogRegistro"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.modalTitle)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
            _vm.resetForm();}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider',{staticClass:"d-none"}),_c('v-card-text',{staticClass:"pl-8 pr-8 pt-5"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-4 pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"autocomplete":"off","dense":"","filled":"","color":"blue-grey lighten-2","label":"Nombre del Eje Katun","rules":[
                  _vm.required('Nombre del Eje Katun'),
                  _vm.minLength('Nombre del Eje Katun', 5),
                  _vm.maxLength('Nombre del Eje Katun', 500)
                ],"maxlength":"500"},model:{value:(_vm.datosItem.nombreEje),callback:function ($$v) {_vm.$set(_vm.datosItem, "nombreEje", $$v)},expression:"datosItem.nombreEje"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.btnRegistroText)+" ")]),_c('v-btn',{staticClass:"mb-2 float-right grey lighten-5 mr-2",attrs:{"color":"grey lighten-5","elevation":"0","disabled":_vm.btnRegistroLoading},on:{"click":function($event){_vm.dialogRegistro = false;
                  _vm.resetForm();}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('div',{staticStyle:{"flex":"1 1 auto"}})],1)],1),_c('v-dialog',{attrs:{"transition":"scroll-y-transition","max-width":"500"},model:{value:(_vm.dialogConfirmarEstado),callback:function ($$v) {_vm.dialogConfirmarEstado=$$v},expression:"dialogConfirmarEstado"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Confirmar "),_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","disabled":false},on:{"click":function($event){_vm.dialogConfirmarEstado = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticStyle:{"letter-spacing":"0.1px !important"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"12","xs":"12"}},[_c('p',{staticClass:"text-h6 black--text  pb-0 mb-0"},[_vm._v(" Está seguro de actualizar la PND \""+_vm._s(_vm.nombreItem)+"\" al estado "+_vm._s(_vm.nuevoEstado)+"? ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 float-right grey lighten-5",attrs:{"color":"grey lighten-5","elevation":"0","disabled":_vm.btncambioEstadoLoading},on:{"click":function($event){_vm.dialogConfirmarEstado = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"loading":_vm.btncambioEstadoLoading},on:{"click":function($event){return _vm.actualizarEstadoItem(_vm.datosItem.id, _vm.nuevoEstadoId)}}},[_vm._v(" Sí ")])],1)],1)],1),_c('DialogLoader',{attrs:{"dialogVisible":_vm.dialogLoaderVisible,"text":_vm.dialogLoaderText}}),_c('SnackAlert',{ref:"snackalert"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }